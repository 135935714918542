<template>
  <div>
    <div
      class="rounded align-center p-3 flex justify-between"
      style="background-color: #dbe2f5"
    >
      <h2 class="text-2xl font-extrabold text-primary text-uppercase">
        Quiz Test <small class="text-gray-500 font-bold">( Total 35 Quiz )</small>
      </h2>
      <div class="flex align-center">
        <div class="bg-periwinkle-gray rounded p-2 ml-2">
          <md-icon class="m-0">filter_alt</md-icon>
        </div>
      </div>
    </div>
    <Table :items="quizzes" @onSelect="onSelect">
      <template
        slot="row"
        slot-scope="{ item }"
        md-selectable="single"
      >
        <md-table-cell md-numeric>{{ item.id }}</md-table-cell>
        <md-table-cell>{{ item.code }}</md-table-cell>
        <md-table-cell>{{ item.title }}</md-table-cell>
        <md-table-cell>
          Audio + Text
        </md-table-cell>
        <md-table-cell>
         <md-button  class="text-victoria text-capitalize font-bold ">
             Completed
         </md-button>
        </md-table-cell>
      </template>
    </Table>
    <div class="flex justify-between align-center my-6">
        <md-button class="bg-victoria text-white text-italic rounded ml-0">Back</md-button>
        <Paginate
        :start="1"
        :end="20"
        :total="100"
        :limit="20"
        />
      </div>
  </div>
</template>

<script>
import { Table, Paginate } from "@/components";
import data from "@/data/quiz-test/data";
export default {
  data() {
    return {
      quizzes: data.quizzes,
    };
  },
  components: {
    Paginate,
      Table
  },
  methods: {
    onSelect(item) {
      this.$router.push({
        name: "student.quizTest.Question",
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style></style>
