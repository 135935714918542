const quizzes = [];

for(let i = 1; i <= 15; i++) {
    quizzes.push({
        id: i, code: 'Q125', title: 'Population of German'
    });
}

export default {
    quizzes
}